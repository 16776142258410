<template>
    <div class="box form-box has-background-white">
        <div class="enso-form">
            <enso-form ref="form">
                <template v-slot:image="props">
                    <div class="columns custom-section-wrapper is-multiline">
                        <div class="column is-12 section-description">
                            <div class="field">
                                <div class="label">
                                    {{ i18n(props.field.label) }}
                                </div>
                            </div>
                        </div>
                        <div class="column is-12 avatar-container">
                            <div class="controls margin-left-16">
                                <div class="flex left-to-right align-center padding-bottom-2">
                                    <uploader :url="route('pins.store', image)"
                                              @upload-successful="addUploadedFile"
                                              file-key="pin"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </enso-form>
        </div>
    </div>
</template>

<script>
import { EnsoForm } from '@enso-ui/forms/bulma';
import { Uploader } from '@enso-ui/uploader/bulma';

export default {
    name: 'Create',
    components: { EnsoForm, Uploader },
    inject: ['i18n', 'route'],
    data: () => ({
        image: null,
    }),
    methods: {
        addUploadedFile() {
            this.$router.push({ name: 'pins.index' });
        },
    },
};
</script>
